@import '../../mixin.scss';

.top {
    height: 100vh;
    background-color: gray;
}

.repair {
    background-color: gray;
    color: white;

    @include onPhone {
        display: grid;
        grid-template-columns: repeat(3, 90px);
        justify-content: space-around;
        gap: 24px;
    }

    @include onTablet {
        display: grid;
        grid-template-columns: repeat(8, 70px);
    }

    @include onDesctop {
        display: grid;
        grid-template-columns: repeat(14, 65px);
    }

    .repair__top {
        display: flex;
        justify-content: center;
        margin: 30px 0 0 0;
        font-family: "Roboto", sans-serif;

        @include onPhone {
            flex-direction: column;
            align-items: center;
            grid-column: 1/4;
            grid-row: 1;
        }

        @include onTablet {
            flex-direction: row;
            grid-column: 1/9;
        }

        @include onDesctop {
            grid-column: 1/14;
        }

        .repair__photo {
            width: 400px;
            height: 200px;

            @include onTablet {
                width: 300px;
                height: 300px;
            }

            @include onDesctop {
                width: 400px;
                height: 350px;
            }
        }

        .repair__container {
            margin: 0 0 0 30px;
            padding: 0 20px 0 0;
            border-right: 1px solid darkgrey;

            @include onPhone {
                text-align: center;
                border-right: none;
                border-top: 1px solid darkgray;
                margin: 30px 0;
                padding: 20px 0;
                max-width: 450px;
            }

            @include onTablet {
                border: none;
            }

            .repair__text {
                margin: 0 0 10px;

                .contacts__item {
                    color: white;
                }
            }

            .repair__title {
                text-align: center;
                width: 450px;
                margin: 0 0 10px;
            }

            .repair__describe {
                text-align: center;
                width: 450px;
                margin: 0 0 10px;
                color: white;
            }
        }
    }

    .repair__box {
        display: flex;
        justify-content: center;
        gap: 20px;

        @include onPhone {
            flex-direction: column;
            align-items: center;
            grid-column: 1/4;
        }

        @include onTablet {
            flex-direction: row;
            grid-column: 1/9;
        }

        @include onDesctop {
            grid-column: 1/14;
        }

        .repair__step {
            text-align: center;
            width: 280px;
            border: 1px solid darkgrey;
            border-top: none;
            font-size: 20px;
            text-transform: uppercase;
        }
    }
}