@import '../../mixin.scss';

.contacts {
    display: flex;
    height: max-content;
    background-color: gray;
    height: 100vh;
    margin: 0;
    justify-content: space-around;
    font-family: "Roboto", sans-serif;
    padding: 30px 0 0;

    @include onPhone {
        flex-direction: column;
        align-items: center;
    }

    @include onDesctop {
        flex-direction: row;
        align-items: start;
    }

    .contacts__container {
        display: flex;
        flex-direction: column;
        width: max-content;

        .contacts__item {
            display: flex;
            align-items: center;
            color: darkslategray;
            transition-duration: 1s;
            text-decoration: none;
            font-size: 14px;
            margin: 10px;

            &:last-child {
                border-bottom: 2px solid darkslategray;
                margin: 0 0 30px;
            }

            &:hover {
                transition-duration: 1s;
                color: white;
            }

            .contacts__icon {
                width: 50px;
                height: 50px;
                margin: 0 15px 0 0;
            }
        }

        .contacts__shedule {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 130px;
            margin: 20px 0 0;

            .contacts__shedule-title {
                color: darkslategray;
                border-bottom: 2px solid darkslategray;
                margin: 0 0 10px;
                width: max-content;
            }

            .contacts__shedule-days {
                color: darkslategray;
                width: max-content;
            }

            .contacts__shedule-hours {
                color: darkslategray;
                border-bottom: 2px solid darkslategray;
                margin: 0 0 10px;
                width: max-content;
            }
        }
    }
}