.paginate {
    display: flex;
    justify-content: center;
    padding: 0 0 20px;
}

.paginate__link {
    width: 40px;
    height: 40px;
    margin: 0 10px 0 0;
    padding: 5px;
    border: none;
    border-radius: 2px;
    background-color: darkgray;
    font-size: 18px;
    cursor: pointer;
    color: #313237;
}

.active-state {
    background-color: darkgrey;
    border: 3px solid white;
    color: white;
}