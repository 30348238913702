@import '../../mixin.scss';

.category {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    flex-wrap: wrap;

    @include onPhone {
        max-width: 600px;
        margin: 20px 0 0;
    }

    @include onTablet {
        max-width: 750px;
    }

    @include onDesctop {
        max-width: 1530px;
        margin: 50px 0 0;
    }

    .category__link {
        display: flex;
        justify-content: space-between;
        text-align: bottom;
        text-transform: uppercase;
        font-weight: bold;
        text-decoration: none;
        color: white;
        border: 2px solid gray;
        border-radius: 5px;
        background-color: rgba(72, 102, 157, 0.7);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        margin: auto;
        padding: 10px;
        text-align: match-parent;

        @include onPhone {
            width: 200px;
            height: 100px;
            border: 1px solid gray;
            font-size: 10px;
        }

        @include onTablet {
            width: 300px;
            height: 180px;
        }

        @include onDesctop {
            width: 220px;
            height: 200px;
            font-size: 19px;
        }
    }

    .mountain {
        background-image: url('../../../public/img/background/mountain.png');
    }

    .road {
        background-image: url('../../../public/img/background/road.png');
    }

    .gravel {
        background-image: url('../../../public/img/background/gravel.png');
    }

    .city {
        background-image: url('../../../public/img/background/city.png');
    }

    .lady {
        background-image: url('../../../public/img/background/lady.png');
    }

    .electro {
        background-image: url('../../../public/img/background/electro.png');
    }
}