@mixin onDesctop {
    @media (min-width: 1024px) {
        @content;
    }
}

@mixin onTablet {
    @media (min-width: 744px) {
        @content;
    }
}

@mixin onPhone {
    @media (min-width: 320px) {
        @content;
    }
}