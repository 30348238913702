.empty {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 100px;
    font-family: "Roboto", sans-serif;


    .empty__container {
        border: 1px solid black;
        border-radius: 10px;
        width: max-content;
        text-align: center;
        padding: 30px;
    }

    .empty__link {
        display: flex;
        align-items: center;
        justify-content: center;
        list-style: none;
        text-decoration: none;
        color: black;
        text-transform: uppercase;
        margin: 0 30px 0 0;
        transition-duration: 1s;
        font-weight: bold;

        &:hover {
            transition-duration: 1s;
            font-weight: bold;
            color: red;
        }
    }

    .empty__icon {
        margin: 0 20px 0 0;
    }

    .empty__text {
        text-transform: uppercase;
    }

    .empty__box {
        display: flex;
        justify-content: center;
    }

}