.home {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    background-image: url('../../../public/img/background/main.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: red;

    .home__category-box {
        display: flex;
        justify-content: center;
    }
}
