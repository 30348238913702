@import '../../mixin.scss';

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
    height: 100px;
    font-family: "Roboto", sans-serif;
    background-color: red;

    @include onPhone {
        display: grid;
        grid-template-columns: repeat(3, 90px);
        justify-content: space-around;
        gap: 24px;
    }

    @include onTablet {
        display: grid;
        grid-template-columns: repeat(8, 70px);
    }

    @include onDesctop {
        display: grid;
        grid-template-columns: repeat(14, 65px);
    }

    .header__mixin-version {
        display: flex;
        align-items: center;
        justify-content: space-around;

        .header__logo {
            border-radius: 50%;
            margin: 0 30px 0 10px;

            @include onPhone {
                width: 70px;
                height: 70px;
            }

            @include onTablet {
                width: 80px;
                height: 80px;
            }

            @include onDesctop {
                width: 90px;
                height: 90px;
            }
        }

        .header__title {
            font-weight: bold;
            background-color: red;
            color: white;
            border-radius: 10px;
            width: max-content;
            height: max-content;

            @include onPhone {
                font-size: 35px;
                border: 2px solid white;
                padding: 5px;
            }

            @include onTablet {
                font-size: 45px;
                border: 3px solid white;
                padding: 10px;
            }

            @include onDesctop {
                //font-size: 45px;
                border: 6px solid white;
                //margin: 0 30px 0 0;
            }
        }

        .header__menu {
            color: #fff;
            transition-duration: 1s;
            border: 1px solid white;
            border-radius: 5px;

            @include onPhone {
                display: block;
                width: 30px;
                height: 30px;
            }

            @include onTablet {
                padding: 5px;

                &:hover {
                    color: black;
                    border: 1px solid black;
                    transition-duration: 1s;
                }
            }

            @include onDesctop {
                display: none;
            }
        }

        @include onPhone {
            grid-column: 1/4;
            grid-row: 1;
        }

        @include onTablet {
            grid-column: 1/7;
            grid-row: 1;
        }

        @include onDesctop {
            grid-column: 1/5;
            grid-row: 1;
        }
    }

    .header__contact-container {

        .header__text {
            text-align: center;
            color: white;
            text-transform: uppercase;
        }

        @include onPhone {
            display: none;
        }

        @include onTablet {
            display: none;
        }

        @include onDesctop {
            display: flex;
            justify-content: space-between;
            grid-column: 7/12;
            grid-row: 1;
        }
    }

    .header__link {
        display: flex;
        text-align: center;
        text-transform: uppercase;
        list-style: none;
        text-decoration: none;
        color: white;
        font-size: 12px;
        transition-duration: 1s;

        &:hover {
            transition-duration: 1s;
            color: black;
        }

        @include onPhone {
            display: none;
        }

        @include onTablet {
            display: none;
        }

        @include onDesctop {
            display: block;
            margin: 0 0 0 50px;
        }

    }

    .header__basket-box {

        .header__basket-i {
            position: relative;

            @include onTablet {
                width: 42px;
                height: 42px;
            }

            @include onDesctop {
                width: 50px;
                height: 50px;
            }

            .header__basket-amount {
                position: absolute;
                text-align: center;
                bottom: 25px;
                left: 30px;
                border-radius: 50%;
                background-color: white;
                color: red;
                width: 20px;
                height: 20px;
                font-weight: bold;
            }
        }

        .header__cart {
            cursor: pointer;
            color: white;
            border: 1px solid white;
            border-radius: 5px;
            transition-duration: 1s;

            &:first-child {
                margin: 0 10px 0 0;
            }

            &:hover {
                color: black;
                border: 1px solid black;
                transition-duration: 1s;
            }

            @include onTablet {
                padding: 10px;
                margin: 0;
            }

            @include onDesctop {
                padding: 15px;
                margin: 0 10px 0 0;
                width: 25px;
                height: 25px;
            }
        }

        @include onPhone {
            display: none;
        }

        @include onTablet {
            display: block;
            grid-column: 7/9;
        }

        @include onDesctop {
            display: flex;
            grid-column: 12/15;
            grid-row: 1;
        }
    }
}