@import '../../mixin.scss';

.search__container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    margin: 0 0 30px 0;

    @include onPhone {
        align-items: center;
    }

    @include onDesctop {
        flex-direction: row;
    }

    .search__input {
        height: 50px;
        width: 400px;
        text-align: center;
        border: none;
        border-radius: 5px;
        margin: 0;
        border: 1px solid lightgray;

        &::placeholder {
            text-transform: uppercase;
            color: gray;
            border: none;
        }
    }
}

.good {
    display: flex;
    justify-content: space-around;
    gap: 10px;
    flex-wrap: wrap;
    width: 1040;
    margin: 0;
    padding: 0 0 40px;
    font-family: "Roboto", sans-serif;

    .good__box {
        text-align: center;
        border: 3px solid white;
        padding: 15px;
        border-radius: 20px;

        .link__style {
            text-decoration: none;
        }

        .good__photo {
            width: 300px;
            height: 300px;
            border-radius: 20px;
        }

        .good__name {
            height: 46px;
            width: 290px;
            color: white;
        }

        .good__price {
            color: white;
        }

        .good__param-box {
            display: flex;
            justify-content: space-between;

            .good__param {
                color: white;
            }

            .good__info {
                color: white;
                border: none;
            }
        }

        .button__box {
            display: flex;
            flex-direction: column;
            align-items: center;

            .good__button {
                cursor: pointer;
                width: 240px;
                height: 48px;
                background-color: #313237;
                border-radius: 5px;
                border: none;
                color: white;
                font-weight: bold;
                text-transform: uppercase;
                margin: 30px 0 0 0;
                transition-duration: 1s;

                &:hover {
                    transition-duration: 1s;
                    background-color: red;
                    color: black;
                    font-weight: bold;
                }

                &:checked {
                    transition-duration: 1s;
                    background-color: darkred;
                    color: white;
                }
            }
        }
    }
}

.pagination__link {
    color: red;
}