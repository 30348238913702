@import '../../mixin.scss';

.title__container {
    margin: 0 0 0 40px;
    display: grid;
    justify-content: center;
    color: #313237;
    text-align: center;
    font-family: "Roboto", sans-serif;

    @include onPhone {
        grid-template-columns: repeat(3, 90px);
        gap: 24px;
        margin: 10px 0;
    }

    @include onTablet {
        grid-template-columns: repeat(8, 70px);
        margin: 20px 0;
    }

    @include onDesctop {
        grid-template-columns: repeat(14, 65px);
        margin: 30px 0;
    }

    .title__button-box {
        display: flex;
        justify-content: center;

        @include onPhone {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            grid-column: 1/4;
        }

        @include onTablet {
            flex-direction: row;
            justify-content: space-between;
            grid-column: 1/7;
        }

        @include onDesctop {
            grid-column: 1/10;
        }
    }

    .button__back {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: none;
        text-decoration: none;

        text-transform: uppercase;
        padding: 5px;
        border-radius: 5px;
        height: max-content;
        width: max-content;
        margin: 0 0 5px;
        font-size: 14px;
        color: #313237;
    }

    .bike__type {
        text-align: center;
        text-transform: uppercase;
        margin: 0 0 10px;
    }

    .bike__amount {
        text-align: center;
        margin: 0;
    }
}