.menu {
    background-color: gray;
    height: 100vh;
    font-family: "Roboto", sans-serif;

    .menu__nav {
        margin: 0;
        padding: 20px 0 0;

        .menu__list {

            .menu__link {
                color: #fff;
                margin: 20px 0;

                &:first-child {
                    margin: 0 0 20px;
                }

                .menu__item {
                    text-decoration: none;
                    color: #fff;
                    text-transform: uppercase;
                    transition-duration: 0.5s;

                    &:hover{
                        transition-duration: 0.5s;
                        transform: scale(1.1);
                        color: darkslategray;
                    }
                }
            }
        }
    }

}